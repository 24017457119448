import {goToByScroll} from './go-to-by-scroll';

// assegna un link a tutto l'elemento padre
export function bindLinkToItem(selector) {

	const items = document.querySelectorAll(selector);

	items.forEach((el) => {
		let anchor;
		let link;

		// ritorna il link dell'elemento con classe "item-link" oppure dell'ultima ancora contenuta nell'elemento
		if (el.querySelector('.item-link') !== null)
		{
			anchor = el.querySelector('.item-link');
		}
		else
		{
			let anchors = el.querySelectorAll('a');
			anchor = anchors[anchors.length - 1];
		}

		if (anchor !== null && (typeof anchor !== 'undefined'))
		{
			link = anchor.getAttribute('href');

			if (link === 'javascript:;' || link.length < 2) // exclude links containing only "#"
			{
				return false;
			}

			if (anchor.classList.contains('venobox'))
			{
				el.addEventListener('click', function (e) {
					anchor.dispatchEvent('click');
				});

				el.classList.add('link-all-item');
			}
			else
			{
				['click', 'mouseup'].forEach(function(event){
					el.addEventListener(event, function (e) {
						// if the e.target is a different element than this and is an anchor, you've clicked on a descendant link,
						// so exit the function and follow the clicked link
						if (e.target.matches('a') && e.target !== this)
						{
							return;
						}

						// if the closest ancestor of the clicked element is an anchor, and it contains "share" in its classes, you've clicked a share link
						// so exit the function and let it perform its task
						if (e.target.closest('a') !== null && e.target.closest('a').getAttribute('class').includes('share'))
						{
							return;
						}

						e.stopPropagation();
						e.preventDefault();

						if (e.isTrusted && (e.ctrlKey || e.which === 2 || anchor.target === '_blank'))
						{
							window.open(link, "_blank");
						}
						else if (e.which === 1)
						{
							if (link.startsWith('#'))
							{
								let element = document.querySelector(link);

								if (element !== null)
								{
									goToByScroll(element, 0);
								}
							}
							else
							{
								location.href = link;
							}
						}
					});
				});

				el.classList.add('link-all-item');
			}
		}
	});
}

bindLinkToItem('.link-all-item');
