import {utils} from "./utils";

export function initAccordions() {

	let toggleAccordion = function (e) {
		e.preventDefault();

		let toggle = e.target;
		const accordionItem = toggle.closest('.module-accordion__item');
		const accordionModule = toggle.closest('.module-accordion');
		const transitionDuration = 600;

		if (toggle !== this && this.contains(toggle))
		{
			toggle = toggle.closest('.toggle-accordion');
		}

		// always keep at least one accordion open
		if (accordionModule.classList.contains('module-accordion--keep-open') && toggle.classList.contains('active'))
		{
			return;
		}

		// prevent from interrupting the animation
		if (toggle.classList.contains('animating'))
		{
			return;
		}
		else
		{
			toggle.classList.add('animating');
		}

		let content = toggle.nextElementSibling;

		if (content !== null)
		{
			toggle.classList.toggle('active');

			const toggleIcon = toggle.querySelector('.tcon');

			if (toggleIcon !== null)
			{
				toggleIcon.classList.toggle('tcon-transform');
			}

			utils.slideToggle(content, transitionDuration);

			setTimeout(() => {
				toggle.classList.remove('animating');
			}, transitionDuration);
		}

		// close other accordions (keep only one accordion open at a time)
		if (accordionModule.classList.contains('module-accordion--single'))
		{
			const otherAccordions = utils.getSiblings(accordionItem);

			otherAccordions.forEach(el => {
				const toggle = el.querySelector('.toggle-accordion');
				const toggleIcon = toggle.querySelector('.tcon');
				const content = toggle.nextElementSibling;

				toggle.classList.remove('active');

				if (toggleIcon !== null)
				{
					toggleIcon.classList.remove('tcon-transform');
				}

				utils.slideUp(content, transitionDuration);
			});
		}
	};

	document.querySelectorAll('.toggle-accordion').forEach(el => {

		el.removeEventListener('click', toggleAccordion);
		el.addEventListener('click', toggleAccordion);

	});

}

initAccordions();