import {goToByScroll} from './go-to-by-scroll';
import {utils} from "./utils";
import {bindLinkToItem} from "./bind-link-to-item";
import {openPopupById} from "./popups";
//import { gsap } from '../libs/gsap.min';
//import { ScrollTrigger } from '../libs/ScrollTrigger.min';

const body = document.body;


/**
 * Add wrapper to Woocommerce selects
 */

document.querySelectorAll('.woocommerce select').forEach(el => {
	const wrapper = document.createElement('div');
	wrapper.classList.add('select-wrapper');

	utils.wrap(el, wrapper);
});

/*

window.addEventListener('DOMContentLoaded', () => {

	fitText(); // init text fit

	document.querySelectorAll('.box').forEach(el => {
	//	new ResizeObserver(fitText).observe(el); // resize of parent container
	});

	window.addEventListener("resize", fitText); // resize of browser

});

// set individual font sizes
let fitTextTimeout = null;

function fitText() {
	clearTimeout(fitTextTimeout);

	fitTextTimeout = setTimeout(function () {
		document.querySelectorAll(".full-title").forEach(el => {
			let fontSize;

			const maxWidth = el.getAttribute('data-width');

			const parentWidth = (maxWidth !== null)? el.parentElement.offsetWidth * maxWidth / 100 : el.parentElement.offsetWidth;
console.log(parentWidth);
			const elementWidth = el.offsetWidth;
			const textWidth = el.innerText.length;

			fontSize =  parentWidth / textWidth;

			el.style.fontSize =  fontSize + 'px';

			if (elementWidth <= parentWidth)
			{
				fontSize = (parentWidth / textWidth) / (elementWidth / parentWidth);
			}
			else
			{
				fontSize = (parentWidth / textWidth) * (elementWidth / parentWidth);
			}

			el.style.fontSize =  fontSize + 'px';
		});
	}, 300);
}
*/


/**
 * Fit text to container
 */
window.addEventListener('DOMContentLoaded', () => {

	fitText(); // init text fit

	window.addEventListener("resizeEnd", fitText); // resize of browser

});

function fitText() {
	document.querySelectorAll(".full-title").forEach(el => {
		let newFontSize;
		let maxWidth = parseInt(el.getAttribute('data-width'));

		if (!Number.isInteger(maxWidth))
		{
			maxWidth = 100;
		}

		if (matchMedia('(max-width: 767.98px)').matches)
		{
			maxWidth = 100;
		}

		maxWidth = maxWidth * 0.97;

		// reset style
		el.removeAttribute('style');

		const parentWidth     = el.parentElement.offsetWidth * maxWidth / 100;
		const elementWidth    = el.getBoundingClientRect().width;
		const elementStyle    = window.getComputedStyle(el);
		const elementFontSize = parseInt(elementStyle.getPropertyValue('font-size'));

		newFontSize = (parentWidth * elementFontSize / elementWidth) / elementFontSize;

		el.style.fontSize = newFontSize + 'em';
	});
}


/**
 * Dynamic images sections
 * @type {NodeListOf<Element>}
 */
const dynamicImagesSections         = document.querySelectorAll('.dynamic-images');
const dynamicImagesSectionsInterval = 5000;

dynamicImagesSections.forEach((section) => {

	const dynamicImagesToggles = section.querySelectorAll('.dynamic-images__toggle');
	const dynamicImagesImages  = section.querySelector('.dynamic-images__images');

	// move first item to the end
	if (dynamicImagesImages !== null)
	{
		let activeImage = dynamicImagesImages.querySelector('[data-id="0"]');
		dynamicImagesImages.appendChild(activeImage);

		let activeToggle = section.querySelector('.dynamic-images__toggle[data-id="0"]');
		activeToggle.classList.add('active');
	}

	/*dynamicImagesToggles.forEach((toggle) => {
		toggle.addEventListener('mouseenter', function (e) {
			this.classList.add('active');

			let otherToggles = utils.getSiblings(this);

			otherToggles.forEach(el => {
				el.classList.remove('active');
			});

			const index = this.getAttribute('data-id');

			let activeImage = dynamicImagesImages.querySelector('[data-id="' + index + '"]');

			// move active item to the end, if it's not already the last
			if (activeImage.nextElementSibling !== null)
			{
				dynamicImagesImages.appendChild(activeImage);
			}
		});
	});*/

	// duplicate texts and set dimensions
	dynamicImagesToggles.forEach((el) => {
		let originalNode = el.querySelector('div');
		let clonedNode   = originalNode.cloneNode(true);

		el.appendChild(clonedNode);
		clonedNode.style.width = originalNode.getBoundingClientRect().width + 'px';

		let span            = clonedNode.querySelector('span');
		span.style.position = 'absolute';
		span.style.width    = '0';

		window.addEventListener('resizeEnd', function () {

			clonedNode.style.width = originalNode.getBoundingClientRect().width + 'px';

		});
	});

	setInterval(function () {
		let currentActiveItem = section.querySelector('.dynamic-images__toggle.active');

		const newIndex = parseInt(currentActiveItem.getAttribute('data-id') + 1) % dynamicImagesToggles.length;

		let newActiveItem = [...dynamicImagesToggles].filter(el => {
			return el.matches('[data-id="' + newIndex + '"]')
		})[0];

		newActiveItem.classList.add('active');

		let otherToggles = utils.getSiblings(newActiveItem);

		otherToggles.forEach(el => {
			el.classList.remove('active');
		});

		let activeImage = dynamicImagesImages.querySelector('[data-id="' + newIndex + '"]');

		// move active item to the end, if it's not already the last
		if (activeImage.nextElementSibling !== null)
		{
			dynamicImagesImages.appendChild(activeImage);
		}
	}, dynamicImagesSectionsInterval);

});


/**
 * sectionProductDetails change image when clicking on accordion
 * @type {NodeListOf<Element>}
 */
const sectionProductDetails = document.querySelectorAll('.section-product-details');

sectionProductDetails.forEach((section) => {

	if (section.querySelector('.section-product-details__accordion') === null)
	{
		return;
	}

	const sectionProductDetailsToggles = section.querySelectorAll('.module-accordion__toggle');
	const sectionProductDetailsImages  = section.querySelector('.section-product-details__image .box-img');
	const sectionProductDetailsGallery = section.querySelector('.section-product-details__gallery');

	// move first item to the end
	if (sectionProductDetailsImages !== null)
	{
		let activeImage = sectionProductDetailsImages.querySelector('img[data-id="1"]');
		sectionProductDetailsImages.appendChild(activeImage);

		let firstGalleryImage           = sectionProductDetailsGallery.querySelector('img[data-id="1"]');
		firstGalleryImage.style.display = 'none';

		let activeImageToggle = section.querySelector('.module-accordion__toggle[data-id="1"]');
		activeImageToggle.classList.add('active-item');
	}

	sectionProductDetailsToggles.forEach((toggle) => {
		toggle.addEventListener('click', function (e) {

			if (toggle.classList.contains('active-item'))
			{
				return;
			}

			toggle.classList.toggle('active-item');

			const index = this.getAttribute('data-id');

			let activeImage        = sectionProductDetailsImages.querySelector('[data-id="' + index + '"]');
			let activeGalleryImage = sectionProductDetailsGallery.querySelector('[data-id="' + index + '"]');
			let oldGalleryImage    = sectionProductDetailsGallery.querySelector('img[style*="display: none"]');

			// move active item to the end, if it's not already the last
			if (activeImage.nextElementSibling !== null)
			{
				sectionProductDetailsImages.appendChild(activeImage);
			}

			activeGalleryImage.style.display = 'none';
			oldGalleryImage.style.display    = 'block';

			const accordionItem   = toggle.closest('.module-accordion__item');
			const otherAccordions = utils.getSiblings(accordionItem);

			otherAccordions.forEach(el => {
				const toggle = el.querySelector('.toggle-accordion');

				toggle.classList.remove('active-item');
			});
		});
	});
});


bindLinkToItem('.post-type-archive-product .product');


let offerPopup = document.getElementById('offer-popup');

if (offerPopup !== null)
{
	let offerPopupAlreadyOpened = sessionStorage.getItem("offerPopupAlreadyOpened");

	if (offerPopupAlreadyOpened !== '1')
	{
		openPopupById('offer-popup');
		sessionStorage.setItem("offerPopupAlreadyOpened", '1');
	}
}


/**
 * Alternate Woocommerce store notices
 * @type {Element}
 */
const storeNotices         = document.querySelector('.woocommerce-store-notice');
const storeNoticesInterval = 5000;

setInterval(alternateStoreNotices, storeNoticesInterval);

function alternateStoreNotices() {
	let currentActiveItem = storeNotices.querySelector('span.active');

	if (currentActiveItem === null)
	{
		currentActiveItem = storeNotices.querySelector('span:first-child');
		currentActiveItem.classList.add('active');
	}

	let nextItem = utils.next(currentActiveItem, 'span')

	if (nextItem === null)
	{
		nextItem = storeNotices.querySelector('span:first-child');
	}

	currentActiveItem.classList.remove('active');
	nextItem.classList.add('active');
}

alternateStoreNotices();


/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {


});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {
	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {



});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/