import {utils} from "./utils";
import autosize from '../libs/autosize.min';

/**
 * Enable/disable CF7 custom submit button when clicking over acceptance field
 */

const cf7Forms = document.querySelectorAll('.wpcf7');

cf7Forms.forEach((form) => {
	let wpcf7Acceptance = form.querySelector('.wpcf7-acceptance:not(.optional) input');
	let wpcf7SubmitButton = form.querySelector('.wpcf7-submit');

	function toggleSubmitButton() {
		if (wpcf7Acceptance !== null)
		{
			let isPrivacyChecked = wpcf7Acceptance.checked;

			wpcf7SubmitButton.disabled = !isPrivacyChecked;
		}
	}

	toggleSubmitButton();

	wpcf7Acceptance.addEventListener('change', function () {
		toggleSubmitButton();
	});
});


/**
 * If a form is inside a popup, scroll the popup to top after submitting the form
 */

const cf7InPopup = document.querySelector('.popup .wpcf7');

if (cf7InPopup !== null)
{
	"wpcf7mailsent wpcf7invalid wpcf7mailfailed wpcf7submit wpcf7spam".split(" ").forEach(function (e) {
		cf7InPopup.addEventListener(e, function () {
			let popupBody = document.querySelector('.popup.popup--active .popup__body');

			popupBody.scrollTo({
				top:      document.querySelector('.popup.popup--active .wpcf7').clientHeight,
				behavior: "smooth"
			});
		});
	});
}

/**
 * Add "focused" class to input wrapper on input's focus, remove on blur
 * @example: <div class="input-wrapper">[email* your-email autocomplete:email]</div>
 */

const cf7Inputs = document.querySelectorAll('.input-wrapper input, .input-wrapper textarea');

cf7Inputs.forEach((input) => {

	input.addEventListener('focus', function () {
		let parents = utils.parents(this, '.input-wrapper');

		parents[0].classList.add('focused');
	});

	input.addEventListener('blur', function () {
		if (this.value === '')
		{
			let parents = utils.parents(this, '.input-wrapper');
			parents[0].classList.remove('focused');
		}
	});

});


/**
 * Autosize text inputs
 */

/*
$('input.resizeable').each(function () {
	let input = $(this);

	input.attr('size', 4);
	input.wrap('<span class="resizeable-wrapper" data-value="' + input.attr('placeholder') + '"></span>');

	input.on('input', function () {
		let input = $(this);

		if (input.val().length > input.attr('placeholder').length)
		{
			input.parent().attr('data-value', input.val());
		}
		else
		{
			input.parent().attr('data-value', input.attr('placeholder'));
		}

	});
});
*/


/**
 * Autosize textareas
 */

let textareas = document.querySelectorAll('textarea.autosize');

autosize(textareas);

/*
[...textareas].forEach(
	(item, pos) => {
		item.addEventListener('autosize:resized', function(){
			// do somenthing when a textarea changes size
		});
	}
);
*/


/**
 * Custom file inputs
 */
let customFileInputs = document.querySelectorAll( '.custom-file-input' );

Array.prototype.forEach.call(customFileInputs, function (input) {
	let label = document.querySelector('label[for=' + input.getAttribute('id') + ']'),
	    labelVal = label.innerHTML;

	input.addEventListener('change', function (e) {
		let fileName = '';

		if (this.files && this.files.length > 1)
		{
			fileName = (label.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
		}
		else
		{
			fileName = e.target.value.split('\\').pop();
		}

		if (fileName)
		{
			label.querySelector('span').innerHTML = fileName;
			label.classList.add('filled-in');
		}
		else
		{
			label.innerHTML = labelVal;
			label.classList.remove('filled-in');
		}
	});

	// Firefox bug fix
	input.addEventListener('focus', function () {
		input.classList.add('has-focus');
	});
	input.addEventListener('blur', function () {
		input.classList.remove('has-focus');
	});
});