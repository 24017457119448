//================================= ANIMAZIONI

/*let appearAnimation = appear({
	elements: function elements(){
		return $('.appear-animation, .fade-in-animation');
	},
	appear: function appear(el){
		$(el).addClass('appear-animated');
	},
	bounds: -50,
	reappear: false
});

let opacityAnimation = appear({
	elements: function elements(){
		return $('.opacity-animation');
	},
	appear: function appear(el){
		$(el).addClass('opacity-animated');
	},
	bounds: -50,
	reappear: false
});*/

/**
 * Init animations
 */
AOS.init({
	offset:   200,
	duration: 1000,
	easing:   'ease-out'
});
