/**
 * ON DOCUMENT READY
 */

let ready = (callback) => {
	if (document.readyState !== "loading")
	{
		callback();
	}
	else
	{
		document.addEventListener("DOMContentLoaded", callback);
	}
}

ready(() => {
	import('./components/utils');

	import('./components/bind-link-to-item');

	import('./components/check-scroll');

	//import('./components/go-to-by-scroll');

	import('./components/toggle-menu');

	import('./components/forms');

	import('./components/custom-checkbox-radio');

	import('./components/slideshows');

	import('./components/popups');

	//import('./components/modal-box');

	import('./components/smooth-scroll');

	//import('./components/columns-extend');

	//import('./components/resize-video-iframe');

	import('./components/toggle-accordion');

	//import('./components/search-form');

	//import('./components/marquee');

	import('./components/animations');

	//import('./components/equal-heights');

	import('./components/theme-scripts');
});