import {utils} from "./utils";

let gallerySlideshowSwiper = new Swiper('.gallery-display-as-slideshow .swiper', {
	slidesPerView: 'auto',
	spaceBetween:  20,
	speed:         500,
	autoHeight:    true,
	pagination:    {
		el:        ".swiper-pagination",
		clickable: true
	},
	/*loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
});

let heroHomeSwiper = new Swiper('.hero-page .swiper', {
	slidesPerView: 1,
	speed:         2000,
	loop:          true,
	autoHeight:    false,
	autoplay:      {
		delay: 8000,
	},
	effect:        'fade',
	fadeEffect:    {
		crossFade: true
	}/*,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}*//*,
	pagination:    {
		el: '.swiper-pagination'
	}*/
});

let ctasSlideshowSwiper = new Swiper('.ctas-slideshow .swiper', {
	slidesPerView: 1,
	speed:         1500,
	autoHeight:    true,/*
	pagination:    {
		el:        ".swiper-pagination",
		clickable: true
	},*/
	loop:          true,
	autoplay:      {
		delay:           4000,
		stopOnLastSlide: false
	},
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
});

let slideshowProductsSwiper = new Swiper('.slideshow-products .swiper', {
	slidesPerView:  2,
	slidesPerGroup: 2,
	spaceBetween:   10,
	speed:          500,
	navigation:     {
		nextEl: '.slideshow-products .swiper-button-next',
		prevEl: '.slideshow-products .swiper-button-prev',
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween:   20
		},
		992: {
			slidesPerView: 4,
			slidesPerGroup: 4
		}
	}
});